<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('trainings.trainings.edit')">
        <actions
          slot="actions"
          crud-links="trainings"
          controller="DcpiTrainings"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <tabs-container :tabs="tabs">
          <template v-slot:data>
            <data-form
              :training="training"
              :loading="loading"
              @submit="submit"
            />
          </template>
          <template v-slot:public>
            <public-details
              :training="training"
              :loading="loading"
              :wizard="false"
              @submit="submitPublic"
            />
          </template>
          <template v-slot:trainers>
            <trainers-table
              :training="training"
              :loading="loading"
              :edit="true"
              @submit="submitTrainers"
            />
          </template>
          <template v-slot:participants>
            <participants-table
              :training="training"
              :loading="loading"
              :edit="true"
              @submit="submitParticipants"
            />
          </template>
          <template v-slot:photos>
            <div class="row">
              <div
                class="flex xs12"
                v-if="training.photos && training.photos.length > 0"
              >
                <h6>{{ $t('trainings.trainings.tabs.photos.current') }}</h6>
                <local-table
                  class="table-hover table-striped"
                  :columns="photosFields"
                  :data="training.photos"
                  :loading="loading"
                >
                  <template v-slot:row-actions="{ props }">
                    <va-popover
                      :message="$t('tables.actions.view')"
                      placement="left"
                    >
                      <va-button
                        flat
                        small
                        color="primary"
                        icon="fa fa-search-plus"
                        @click.prevent="showLightbox(props.rowData.path, training.id)"
                      />
                    </va-popover>
                    <va-popover
                      :message="$t('tables.actions.delete')"
                      placement="left"
                    >
                      <va-button
                        flat
                        small
                        color="danger"
                        icon="fa fa-times"
                        @click.prevent="tryDeletePhoto(props.rowData)"
                      />
                    </va-popover>
                  </template>
                </local-table>
                <light-box
                  ref="lightbox"
                  :show-caption="true"
                  :show-light-box="false"
                  :show-thumbs="false"
                  :media="filteredImages"
                >
                </light-box>
              </div>
              <hr>
              <div class="flex xs12">
                <h6>{{ $t('trainings.trainings.tabs.photos.upload') }}</h6>
                <photos-upload
                  ref="photoUpload"
                  :wizard="false"
                  @submit="savePhotos"
                />
              </div>
            </div>
          </template>
          <template v-slot:documents>
            <div class="row">
              <div
                class="flex xs12"
                v-if="training.material && training.material.length > 0"
              >
                <h6>{{ $t('trainings.trainings.tabs.documents.current') }}</h6>
                <local-table
                  class="table-hover table-striped"
                  :columns="documentsFields"
                  :data="training.material"
                  :loading="loading"
                >
                  <template v-slot:row-actions="{ props }">
                    <!--                     <va-popover
                      :message="$t('tables.actions.view')"
                      placement="left"
                    >
                      <va-button
                        flat
                        small
                        color="primary"
                        icon="fa fa-search-plus"
                        @click.prevent="showLightbox2(props.rowData.path, training.id)"
                      />
                    </va-popover> -->
                    <va-popover
                      :message="$t('tables.actions.delete')"
                      placement="left"
                    >
                      <va-button
                        flat
                        small
                        color="danger"
                        icon="fa fa-times"
                        @click.prevent="tryDeleteMaterial(props.rowData)"
                      />
                    </va-popover>
                  </template>
                </local-table>
                <light-box
                  ref="lightbox"
                  :show-caption="true"
                  :show-light-box="false"
                  :show-thumbs="false"
                  :media="filteredMaterial"
                >
                </light-box>
              </div>
              <hr>
              <div class="flex xs12">
                <h6>{{ $t('trainings.trainings.tabs.documents.upload') }}</h6>
                <materials-upload
                  ref="materialsUpload"
                  :wizard="false"
                  @submit="saveDocuments"
                />
              </div>
            </div>
          </template>
        </tabs-container>
      </va-card>
    </div>
  </div>
</template>

<script>
const DataForm = () => import(/* webpackPrefetch: true */ './Form')
const PhotosUpload = () => import(/* webpackPrefetch: true */ './Photos')
const materialsUpload = () => import(/* webpackPrefetch: true */ './Documents')
const TrainersTable = () => import(/* webpackPrefetch: true */ './Trainers')
const LightBox = () => import(/* webpackPrefetch: true */ 'vue-image-lightbox')
const PublicDetails = () => import(/* webpackPrefetch: true */ './PublicDetails')
const ParticipantsTable = () => import(/* webpackPrefetch: true */ './Participants')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'trainings-edit',
  components: {
    Actions,
    DataForm,
    LightBox,
    LocalTable,
    PhotosUpload,
    PublicDetails,
    TrainersTable,
    TabsContainer,
    ParticipantsTable,
    materialsUpload,
  },
  data () {
    return {
      error: false,
      loading: false,
      training: {},
      images: [],
      documents: [],
      actions: ['index', 'new', 'view', 'delete'],
      galleryFilter: null,
      archivoFilter: null,
    }
  },
  computed: {
    filteredImages: function () {
      if (this.galleryFilter === 'all') {
        return this.images
      } else {
        return this.images.filter(image => image.filter === this.galleryFilter)
      }
    },
    filteredMaterial: function () {
      if (this.archivoFilter === 'all') {
        return this.documents
      } else {
        return this.documents.filter(document => document.filter === this.archivoFilter)
      }
    },
    tabs () {
      return [
        { name: 'data', title: this.$t('trainings.trainings.tabs.data.title') },
        { name: 'public', title: this.$t('trainings.trainings.tabs.public.title'), change: this.onPublicTab },
        { name: 'trainers', title: this.$t('trainings.trainings.tabs.trainers.title') },
        { name: 'participants', title: this.$t('trainings.trainings.tabs.participants.title') },
        { name: 'photos', title: this.$t('trainings.trainings.tabs.photos.title') },
        { name: 'documents', title: this.$t('trainings.trainings.tabs.documents.title') },
      ]
    },
    photosFields () {
      return [
        {
          name: 'path',
          title: this.$t('tables.headings.image'),
        },
        {
          name: '__slot:actions',
          visible: this.training.photos && this.training.photos.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
    documentsFields () {
      return [
        {
          name: 'path_ma',
          title: this.$t('tables.headings.document'),
        },
        {
          name: '__slot:actions',
          visible: this.training.material && this.training.material.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  async created () {
    await this.updateData()
    this.setImages()
    this.setMaterial()
  },
  methods: {
    async onPublicTab () {
      await this.$nextTick()
      window.dispatchEvent(new Event('resize'))
    },
    getPath (url) {
      let base = this.$http.defaults.baseURL.slice(0)
      if (base) {
        if (/\/api\//g.test(base)) {
          base = base.replace('/api/', '')
        }
        if (!/^https?:\/\/[^/]+/g.test(base)) {
          base = window.origin + base
        }
        url = base + url
      }

      return url
    },
    setImages () {
      if (!this.training || !this.training.photos) {
        return
      }

      const images = []
      for (const image of this.training.photos) {
        const i = {
          id: image.id,
          src: this.getPath(image.path),
          caption: '',
          filter: this.training.id.toString(),
        }

        images.push(i)
      }

      this.images = images.slice(0)
    },
    setMaterial () {
      if (!this.training || !this.training.material) {
        return
      }

      const Documento = []
      for (const Doc of this.training.material) {
        const i = {
          id: Doc.id,
          src: this.getPath(Doc.path_ma),
          caption: '',
          filter: this.training.id.toString(),
        }

        Documento.push(i)
      }

      this.documents = Documento.slice(0)
    },
    showLightbox2: function (path, filter) {
      this.archivoFilter = filter.toString()
      path = this.getPath(path)

      const index = this.filteredMaterial.findIndex(i => i.src === path)
      this.$refs.lightbox.showImage(index)
    },
    showLightbox: function (path, filter) {
      this.galleryFilter = filter.toString()
      path = this.getPath(path)

      const index = this.filteredImages.findIndex(i => i.src === path)
      this.$refs.lightbox.showImage(index)
    },
    routeBuilder (id) {
      return `trainings/dcpi/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const trainingId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(trainingId))
      } catch (err) {
        // console.log('Error fetching training data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.training = u.data.data
    },
    async submitTrainers (trainers) {
      const t = {
        id: this.training.id,
        trainers: trainers.users.to_add,
        trainers_to_delete: trainers.users.to_delete,
      }
      const success = await this.submit(t)
      if (success) {
        this.updateData()
      }
    },
    async submitParticipants (participants) {
      const t = {
        id: this.training.id,
        participants: participants.users.to_add,
        participants_to_delete: participants.users.to_delete,
      }
      const success = await this.submit(t)
      if (success) {
        this.updateData()
      }
    },
    async tryDeletePhoto (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete(`trainings/dcpi/${item.id}?photo=true`)
      } catch (err) {
        // console.log('Error deleting photo', err)
        this.loading = false
        return
      }
      await this.updateData()
      this.setImages()
    },
    async tryDeleteMaterial (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete(`trainings/dcpi/${item.id}?material=true`)
      } catch (err) {
        // console.log('Error deleting photo', err)
        this.loading = false
        return
      }
      await this.updateData()
      this.setMaterial()
    },
    async submitPublic (data) {
      this.loading = true
      this.submitError = false
      const trainingId = this.$route.params.id

      const training = new FormData()
      training.append('id', trainingId)
      training.append('_method', 'PUT')
      const keys = Object.keys(data)
      for (const k of keys) {
        training.append(k, data[k])
      }

      try {
        await this.$http.post('trainings/dcpi/' + trainingId, training, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        // console.log('Error uploading photo', error)
        this.loading = false
        this.submitError = true
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      await this.updateData()
    },
    async savePhotos (photos) {
      this.loading = true
      this.submitError = false
      const trainingId = this.$route.params.id

      const training = new FormData()
      training.append('id', trainingId)
      training.append('_method', 'PUT')
      for (const image of photos) {
        training.append('dcpi_photos[]', image)
      }

      try {
        await this.$http.post('trainings/dcpi/' + trainingId, training, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        // console.log('Error uploading photo', error)
        this.loading = false
        this.submitError = true
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      await this.updateData()
      this.setImages()
      this.$refs.photoUpload.gallery = []
    },
    async saveDocuments (material) {
      this.loading = true
      this.submitError = false
      const trainingId = this.$route.params.id

      const training = new FormData()
      training.append('id', trainingId)
      training.append('_method', 'PUT')
      for (const doc of material) {
        training.append('dcpi_material[]', doc)
      }
      console.log('training')
      console.log(training)

      try {
        await this.$http.post('trainings/dcpi/' + trainingId, training, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        // console.log('Error uploading photo', error)
        this.loading = false
        this.submitError = true
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      await this.updateData()
      this.setMaterial()
      this.$refs.materialsUpload.material = []
    },
    async submit (training) {
      const t = Object.assign({}, training)
      delete t.image_path
      this.loading = true
      try {
        await this.$http.put(this.routeBuilder(training.id), t)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return false
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      return true
    },
  },
}
</script>

<style scoped>
.gallery-image {
  cursor: pointer;
}
</style>
